import { returnHubspotPropKey } from '@utils/pains';
import { IUser, IUserLandingPage } from '@utils/user';
import axios, { AxiosResponse } from 'axios';

const api = axios.create({
  baseURL: process.env.NEXT_PUBLIC_SERVICES_URI,
});
interface ISubscribeConference {
  userID: string;
  eventID: string;
  profile?: boolean;
}

export const subscribeConference = async ({
  userID,
  eventID,
  profile = false,
}: ISubscribeConference): Promise<AxiosResponse> =>
  api.post(`/event-enroll`, {
    userID,
    eventID,
    profile,
  });

export const updateHubspotProps = async (
  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  userData: IUser | IUserLandingPage,
  eventSlug = '',
  actionName = '',
): Promise<void> => {
  const props = [];

  // eslint-disable-next-line
  for (let prop in userData) {
    const key = returnHubspotPropKey(prop);
    if (key) {
      props.push([key, userData[prop]]);
    }
  }

  try {
    await api.post('/update-hubspot-props', {
      email: userData.email,
      props,
      eventSlug,
      actionName,
    });
  } catch (err) {
    console.log(err);
  }
};
