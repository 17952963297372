import { IEventAttendeeResponse, IEvent } from '@models/event.interface';
import api from '../api';

export const getEventAttendee = async (
  userId: string,
): Promise<Array<{ event: IEvent }>> => {
  const resp = await api.get<IEventAttendeeResponse>(
    `/event-attendee?filter[attendee]=${userId}`,
  );

  return resp.data.rows;
};

export const getEventId = async (eventId: string): Promise<IEvent> => {
  const resp = await api.get(`/event/${eventId}`);

  return resp.data;
};

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const getEventSpeakersAndEhxibitors = async (slug: string) => {
  const resp = await api.get(`/event/slug/${slug}/speakers-and-exhibitors`);

  return resp.data;
};

export const getEventSlug = async (eventSlug: string): Promise<IEvent> => {
  const event = await api.get<IEvent>(`/event/slug/${eventSlug}`);
  return event.data[0];
};
